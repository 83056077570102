<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        Trésorerie > Trésorerie prévisionnelle
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Trésorerie prévisionnelle
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période de base: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <label for="">Nombre d'années de prévision: </label>
          <input class="form-control col-2 mx-2" 
                 name="" 
                 id=""
                 type="number"
                 v-model="nb_periode"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <div class="row my-2">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="800"
                  filename="Trésorerie_previsionnelle"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="landscape"
                  pdf-content-width="1125px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-3">
          <div class="col-1 ml-4">
            <img src="../../assets/logo.png" 
                 style="width:80px"/>
          </div>
          <div class="col-5">
          </div>
          
        </div>
        <div class="row justify-content-center mb-4">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Trésorerie Prévisionnelle
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;">
          <table class="table tablestyle_3 table-striped">
            <thead>
              <tr v-if="previsionsRevenu">
                <th style="width:12%;border-top: none;"></th>
                <template v-for="(anterieur,akey) in previsionsRevenu.autres_entrees[0].periode_anterieur">
                  <th :key="'anAn'+akey"
                      style="width:12%;"
                      class="text-right th-blue">{{ anterieur }}</th>
                </template>
                <template v-for="(prevision,pkey) in previsionsRevenu.autres_entrees[0].periode_prevision">
                  <th :key="'anPr'+pkey"
                      style="width:12%;"
                      class="text-right tht-blue">{{ prevision }}</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <!-- Revenus -->

              <tr v-if="previsionsRevenu">
                <td class="th-blue">Banque</td>
                <template v-for="(revenu_ant,ankey) in previsionsRevenu.banque[0].montant_anterieur">
                  <td :key="'reAn'+ankey"
                      style="width:12%;"
                      class="text-right">{{ revenu_ant.toLocaleString() }}</td>
                </template>
                <template v-for="(revenu_prev,prkey) in previsionsRevenu.banque[0].chiffre_affaire_prevision">
                  <td :key="'rePr'+prkey"
                      style="width:12%;"
                      class="text-right">{{ revenu_prev.toLocaleString() }}</td>
                </template>
              </tr>
              <tr v-if="previsionsRevenu">
                <td class="th-blue">Caisse</td>
                <template v-for="(revenu_ant,ankey) in previsionsRevenu.caisse[0].montant_anterieur">
                  <td :key="'reAn'+ankey"
                      style="width:12%;"
                      class="text-right">{{ revenu_ant.toLocaleString() }}</td>
                </template>
                <template v-for="(revenu_prev,prkey) in previsionsRevenu.caisse[0].chiffre_affaire_prevision">
                  <td :key="'rePr'+prkey"
                      style="width:12%;"
                      class="text-right">{{ revenu_prev.toLocaleString() }}</td>
                </template>
              </tr>
              <tr v-if="previsionsRevenu">
                <td class="th-blue">Autres entrées</td>
                <template v-for="(revenu_ant,ankey) in previsionsRevenu.autres_entrees[0].montant_anterieur">
                  <td :key="'reAn'+ankey"
                      style="width:12%;"
                      class="text-right">{{ revenu_ant.toLocaleString() }}</td>
                </template>
                <template v-for="(revenu_prev,prkey) in previsionsRevenu.autres_entrees[0].chiffre_affaire_prevision">
                  <td :key="'rePr'+prkey"
                      style="width:12%;"
                      class="text-right">{{ revenu_prev.toLocaleString() }}</td>
                </template>
              </tr>
              <tr v-if="previsionsRevenu">
                <td class="tht-blue">RESSOURCES</td>
                <template v-for="(revenu_ant,ankey) in revenus.anterieur">
                  <td :key="'reAn'+ankey"
                      style="width:12%;"
                      class="text-right tht-blue">{{ revenu_ant.toLocaleString() }}</td>
                </template>
                <template v-for="(revenu_prev,prkey) in revenus.prevision">
                  <td :key="'rePr'+prkey"
                      style="width:12%;"
                      class="text-right tht-blue">{{ revenu_prev.toLocaleString() }}</td>
                </template>
              </tr>
              <!-- Charges -->
              <tr v-if="previsionsRevenu">
                <td class="th-blue">Décompte</td>
                <template v-for="(revenu_ant,ankey) in previsionsRevenu.decompte[0].montant_anterieur">
                  <td :key="'reAn'+ankey"
                      style="width:12%;"
                      class="text-right">{{ revenu_ant.toLocaleString() }}</td>
                </template>
                <template v-for="(revenu_prev,prkey) in previsionsRevenu.decompte[0].chiffre_affaire_prevision">
                  <td :key="'rePr'+prkey"
                      style="width:12%;"
                      class="text-right">{{ revenu_prev.toLocaleString() }}</td>
                </template>
              </tr>
              <tr v-if="previsionsRevenu">
                <td class="th-blue">Autres sorties</td>
                <template v-for="(revenu_ant,ankey) in previsionsRevenu.autres_sorties[0].montant_anterieur">
                  <td :key="'reAn'+ankey"
                      style="width:12%;"
                      class="text-right">{{ revenu_ant.toLocaleString() }}</td>
                </template>
                <template v-for="(revenu_prev,prkey) in previsionsRevenu.autres_sorties[0].chiffre_affaire_prevision">
                  <td :key="'rePr'+prkey"
                      style="width:12%;"
                      class="text-right">{{ revenu_prev.toLocaleString() }}</td>
                </template>
              </tr>
              <tr v-if="previsionsRevenu">
                <td class="tht-blue">EMPLOIS</td>
                <template v-for="(charge_ant,ankey) in charges.anterieur">
                  <td :key="'charAn'+ankey"
                      style="width:12%;"
                      class="text-right tht-blue">{{ charge_ant.toLocaleString() }}</td>
                </template>
                <template v-for="(charge_prev,prkey) in charges.prevision">
                  <td :key="'charPr'+prkey"
                      style="width:12%;"
                      class="text-right tht-blue">{{ charge_prev.toLocaleString() }}</td>
                </template>
              </tr>
              <!-- Résultat -->
              <tr v-if="previsionsRevenu">
                <td class="tht-dark-blue">RESULTAT</td>
                <template v-for="(total_ant,ankey) in total.anterieur">
                  <td :key="'totAn'+ankey"
                      style="width:12%;"
                      class="text-right tht-dark-blue">{{ total_ant.toLocaleString() }}</td>
                </template>
                <template v-for="(total_prev,prkey) in total.prevision">
                  <td :key="'totPr'+prkey"
                      style="width:12%;"
                      class="text-right tht-dark-blue">{{ total_prev.toLocaleString() }}</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <div class="row">
      <table class="table tablestyle_3 table-striped">
        <thead>
          <tr v-if="previsionsRevenu">
            <th style="width:12%;border-top: none;"></th>
            <template v-for="(anterieur,akey) in previsionsRevenu.autres_entrees[0].periode_anterieur">
              <th :key="'anAn'+akey"
                  style="width:12%;"
                  class="text-right th-blue">{{ anterieur }}</th>
            </template>
            <template v-for="(prevision,pkey) in previsionsRevenu.autres_entrees[0].periode_prevision">
              <th :key="'anPr'+pkey"
                  style="width:12%;"
                  class="text-right tht-blue">{{ prevision }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <!-- Revenus -->
          
          <tr v-if="previsionsRevenu">
            <td class="th-blue">Banque</td>
            <template v-for="(revenu_ant,ankey) in previsionsRevenu.banque[0].montant_anterieur">
              <td :key="'reAn'+ankey"
                  style="width:12%;"
                  class="text-right">{{ revenu_ant.toLocaleString() }}</td>
            </template>
            <template v-for="(revenu_prev,prkey) in previsionsRevenu.banque[0].chiffre_affaire_prevision">
              <td :key="'rePr'+prkey"
                  style="width:12%;"
                  class="text-right">{{ revenu_prev.toLocaleString() }}</td>
            </template>
          </tr>
          <tr v-if="previsionsRevenu">
            <td class="th-blue">Caisse</td>
            <template v-for="(revenu_ant,ankey) in previsionsRevenu.caisse[0].montant_anterieur">
              <td :key="'reAn'+ankey"
                  style="width:12%;"
                  class="text-right">{{ revenu_ant.toLocaleString() }}</td>
            </template>
            <template v-for="(revenu_prev,prkey) in previsionsRevenu.caisse[0].chiffre_affaire_prevision">
              <td :key="'rePr'+prkey"
                  style="width:12%;"
                  class="text-right">{{ revenu_prev.toLocaleString() }}</td>
            </template>
          </tr>
          <tr v-if="previsionsRevenu">
            <td class="th-blue">Autres entrées</td>
            <template v-for="(revenu_ant,ankey) in previsionsRevenu.autres_entrees[0].montant_anterieur">
              <td :key="'reAn'+ankey"
                  style="width:12%;"
                  class="text-right">{{ revenu_ant.toLocaleString() }}</td>
            </template>
            <template v-for="(revenu_prev,prkey) in previsionsRevenu.autres_entrees[0].chiffre_affaire_prevision">
              <td :key="'rePr'+prkey"
                  style="width:12%;"
                  class="text-right">{{ revenu_prev.toLocaleString() }}</td>
            </template>
          </tr>
          <tr v-if="previsionsRevenu">
            <td class="tht-blue">RESSOURCES</td>
            <template v-for="(revenu_ant,ankey) in revenus.anterieur">
              <td :key="'reAn'+ankey"
                  style="width:12%;"
                  class="text-right tht-blue">{{ revenu_ant.toLocaleString() }}</td>
            </template>
            <template v-for="(revenu_prev,prkey) in revenus.prevision">
              <td :key="'rePr'+prkey"
                  style="width:12%;"
                  class="text-right tht-blue">{{ revenu_prev.toLocaleString() }}</td>
            </template>
          </tr>
          <!-- Charges -->
          <tr v-if="previsionsRevenu">
            <td class="th-blue">Décompte</td>
            <template v-for="(revenu_ant,ankey) in previsionsRevenu.decompte[0].montant_anterieur">
              <td :key="'reAn'+ankey"
                  style="width:12%;"
                  class="text-right">{{ revenu_ant.toLocaleString() }}</td>
            </template>
            <template v-for="(revenu_prev,prkey) in previsionsRevenu.decompte[0].chiffre_affaire_prevision">
              <td :key="'rePr'+prkey"
                  style="width:12%;"
                  class="text-right">{{ revenu_prev.toLocaleString() }}</td>
            </template>
          </tr>
          <tr v-if="previsionsRevenu">
            <td class="th-blue">Autres sorties</td>
            <template v-for="(revenu_ant,ankey) in previsionsRevenu.autres_sorties[0].montant_anterieur">
              <td :key="'reAn'+ankey"
                  style="width:12%;"
                  class="text-right">{{ revenu_ant.toLocaleString() }}</td>
            </template>
            <template v-for="(revenu_prev,prkey) in previsionsRevenu.autres_sorties[0].chiffre_affaire_prevision">
              <td :key="'rePr'+prkey"
                  style="width:12%;"
                  class="text-right">{{ revenu_prev.toLocaleString() }}</td>
            </template>
          </tr>
          <tr v-if="previsionsRevenu">
            <td class="tht-blue">EMPLOIS</td>
            <template v-for="(charge_ant,ankey) in charges.anterieur">
              <td :key="'charAn'+ankey"
                  style="width:12%;"
                  class="text-right tht-blue">{{ charge_ant.toLocaleString() }}</td>
            </template>
            <template v-for="(charge_prev,prkey) in charges.prevision">
              <td :key="'charPr'+prkey"
                  style="width:12%;"
                  class="text-right tht-blue">{{ charge_prev.toLocaleString() }}</td>
            </template>
          </tr>
          <!-- Résultat -->
          <tr v-if="previsionsRevenu">
            <td class="tht-dark-blue">RESULTAT</td>
            <template v-for="(total_ant,ankey) in total.anterieur">
              <td :key="'totAn'+ankey"
                  style="width:12%;"
                  class="text-right tht-dark-blue">{{ total_ant.toLocaleString() }}</td>
            </template>
            <template v-for="(total_prev,prkey) in total.prevision">
              <td :key="'totPr'+prkey"
                  style="width:12%;"
                  class="text-right tht-dark-blue">{{ total_prev.toLocaleString() }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style>
input.form-control,
label,
.btn,
table.table td.text-right{
  font-size: 0.7em;
}
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import VueHtml2pdf from 'vue-html2pdf'

export default ({
  name:"PrevisionFinancière",
  components:{
    VueHtml2pdf
  },
  data:()=>({
    annee_debut: (new Date().getFullYear()-1)+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    nb_periode:5,
    annees:0,
    long:0,
    revenus:"",
    charges:"",
    loaded:false,
    total:""
  }),
  watch:{
    previsionsRevenu(){
      if (this.previsionsRevenu) {
        console.log(this.previsionsRevenu)
        this.revenus={anterieur:[],prevision:[]}
        this.charges={anterieur:[],prevision:[]}
        this.total={anterieur:[],prevision:[]}
        for (let index = 0; index < this.previsionsRevenu.autres_entrees[0].periode_anterieur.length; index++) {
          this.revenus.anterieur.push(0)
          this.charges.anterieur.push(0)
          this.total.anterieur.push(0)
          this.revenus.anterieur[index]+=this.previsionsRevenu.autres_entrees[0].montant_anterieur[index]
            +this.previsionsRevenu.caisse[0].montant_anterieur[index]
            +this.previsionsRevenu.banque[0].montant_anterieur[index]
          this.charges.anterieur[index]+=this.previsionsRevenu.autres_sorties[0].montant_anterieur[index]+this.previsionsRevenu.decompte[0].montant_anterieur[index]
          this.total.anterieur[index]+=Number(this.revenus.anterieur[index]) - Number(this.charges.anterieur[index])
        }
        if (this.previsionsRevenu.autres_entrees[0].periode_prevision.length > 0) {
          for (let index = 0; index < this.previsionsRevenu.autres_entrees[0].periode_prevision.length; index++) {
            this.revenus.prevision.push(0)
            this.charges.prevision.push(0)
            this.total.prevision.push(0)
            this.revenus.prevision[index]+=this.previsionsRevenu.autres_entrees[0].chiffre_affaire_prevision[index]
              +this.previsionsRevenu.caisse[0].chiffre_affaire_prevision[index]
              +this.previsionsRevenu.banque[0].chiffre_affaire_prevision[index]
            this.charges.prevision[index]+=this.previsionsRevenu.autres_sorties[0].chiffre_affaire_prevision[index]+this.previsionsRevenu.decompte[0].chiffre_affaire_prevision[index]
            this.total.prevision[index]+=Number(this.revenus.prevision[index]) - Number(this.charges.prevision[index])

          }
        }
      }
      this.loaded=true
      // console.log(this.revenus)
    }
  },
  created(){
    this.loadPrevisionsRevenu({date_debut: this.annee_debut,date_fin: this.annee_fin, nb_periode: this.nb_periode})
  },
  computed:{
    ...mapGetters(["previsionsRevenu","previsionsCharge",]),
  },
  methods:{
    ...mapActions(["loadPrevisionsRevenu","loadPrevisionsCharge",]),
    ...mapMutations(["setPrevisionsRevenu","setMsgFailPrevisionsRevenu","setPrevisionsCharge","setMsgFailPrevisionsCharge"]),
    changeYear() {
      this.setPrevisionsRevenu("")
      this.setPrevisionsCharge("")
      this.loadPrevisionsRevenu({date_debut: this.annee_debut,date_fin: this.annee_fin, nb_periode: this.nb_periode})
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
})
</script>
